import styled from 'styled-components'

import { transition } from '../../../../common/mixins'
import media from '../../../../common/MediaQueries'

import { StyledNavArrow } from '../styles'

const StyledShadowedArrows = styled(StyledNavArrow)`
  width: 3.875rem;
  height: 3.875rem;
  ${transition}

  svg {
    font-size: 3rem;
  }

  @media ${media.tablet} {
    svg {
      font-size: 3.875rem;
    }
  }
`

const StyledShadowedArrowPrev = styled(StyledShadowedArrows)`
  transform: translate(-50%, -50%) rotate(-180deg);

  &:hover {
    transform: translate(-50%, -50%) rotate(-180deg) scale3d(1.05, 1.05, 1.05);
  }
`

const StyledShadowedArrowNext = styled(StyledShadowedArrows)`
  transform: translate(50%, -50%);

  &:hover {
    transform: translate(50%, -50%) scale3d(1.05, 1.05, 1.05);
  }
`

export { StyledShadowedArrowPrev, StyledShadowedArrowNext }
