import React from 'react'

import ArrowRightLongIcon from '../../../Icons/ArrowRightLongIcon'
import { StyledSquareArrowPrev, StyledSquareArrowNext } from './styles'

export const PrevButton = ({ onClick }) => (
  <StyledSquareArrowPrev aria-label="prev" {...{ onClick }}>
    <ArrowRightLongIcon />
  </StyledSquareArrowPrev>
)

export const NextButton = ({ onClick }) => (
  <StyledSquareArrowNext aria-label="next" {...{ onClick }}>
    <ArrowRightLongIcon />
  </StyledSquareArrowNext>
)
