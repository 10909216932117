import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import { rgba } from 'polished'

import {
  decorReverseLink,
  decorHoverReverseLink,
  transition,
} from '../../../common/mixins'
import media, { customMedia } from '../../../common/MediaQueries'
import { BG_TYPES } from '../../../constants/bgTypes'
import { Body, Typo5 } from '../../Typography/Typography'

const StyledOtherModelDetailsDesc = styled(Body)`
  margin-bottom: 1.5rem;
  line-height: 1.8;
  font-size: 0.875rem;
  margin-left: 0.75rem;

  @media ${media.tabletSmall} {
    margin-bottom: 2rem;
    line-height: 1.5625;
    font-size: 1rem;
    margin-left: 2.5rem;
  }
`

const StyledOtherModelDetailsTitle = styled.div`
  position: relative;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 4;
  letter-spacing: 7.5px;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: -.25rem;
    left: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.5)};
    width: 3.125rem;
    height: 1px;
  }


  ${({ bg }) =>
    bg === BG_TYPES.black &&
    css`
      &::before {
        background: ${({ theme }) => rgba(theme.colors.white, 0.5)};
      }
    `}


  @media ${media.tabletSmall} {
    padding-left: 2.5rem;
  }
`

const StyledOtherModelDetailsAction = styled(Typo5)`
  display: inline-flex;
  margin-left: 0.75rem;
  letter-spacing: 1px;
  ${transition}
  ${decorReverseLink}
  

  @media ${media.tabletSmall} {
    margin-left: 2.5rem;
  }
`

const StyledOtherModelDetailsName = styled.div`
  font-size: 2.375rem;
  letter-spacing: 0.82px;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  ${transition}


  @media ${media.tabletSmall} {
    font-size: 3rem;
    letter-spacing: 1.3px;
  }

  @media ${media.tablet} {
    font-size: 3.75rem;
  }
`

const StyledOtherModelDetailsLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.5rem 2.5rem 3rem 1.875rem;

  &:hover {
    ${StyledOtherModelDetailsName},
    ${StyledOtherModelDetailsAction} {
      color: ${({ theme }) => theme.colors.yellow};
    }

    ${StyledOtherModelDetailsAction} {
      ${decorHoverReverseLink}
    }
  }

  @media ${media.tabletSmall} {
    padding: 4.5rem 6rem 4.5rem 6.75rem;
  }

  @media ${media.tablet} {
    padding: 6.25rem 6.25rem 6.25rem 7.8125rem;
  }
`

const StyledOtherModelDetails = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: inherit;

  @media ${customMedia.maxTabletSmall} {
    ${({ alt }) =>
      alt &&
      css`
        ${StyledOtherModelDetailsTitle} {
          display: flex;
          justify-content: flex-end;

          &::before {
            top: 50%;
            transform: translateY(-50%);
          }
        }

        ${StyledOtherModelDetailsLink} {
          padding: 1.25rem;
        }

        ${StyledOtherModelDetailsDesc},
        ${StyledOtherModelDetailsAction} {
          padding-left: 0;
        }
      `}
  }
`

export {
  StyledOtherModelDetails,
  StyledOtherModelDetailsLink,
  StyledOtherModelDetailsTitle,
  StyledOtherModelDetailsName,
  StyledOtherModelDetailsDesc,
  StyledOtherModelDetailsAction,
}
