import React from 'react'

import Image from '../../Image/Image'
import { StyledOtherModelImage } from './styles'
import { fluidPropType } from '../../../constants/proptypes'

const OtherModelImage = ({ fluid }) => (
  <StyledOtherModelImage>
    <Image {...{ fluid }} />
  </StyledOtherModelImage>
)

export default OtherModelImage

OtherModelImage.propTypes = {
  fluid: fluidPropType,
}
