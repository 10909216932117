import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import media from '../../../common/MediaQueries'

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    display: flex;
    width: 100%;

    div {
      &:focus {
        outline: none;
      }
    }
    div:not([class]) {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  @media ${media.phone} {
    .slick-slide {
      cursor: grab;
      height: auto;
    }
  }
`

const StyledSliderSlide = styled.div`
  @media ${media.phone} {
    height: 100%;
  }
`

export { StyledSlider, StyledSliderSlide }
