import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowRightLongIcon = () => (
  <IconWrapper width="2.25">
    <svg viewBox="0 0 26.6 12" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M26.5 5H4.6v2h21.9V5z" />
      <path fill="currentColor" d="M5.6.1L0 6l5.6 5.9V.1z" />
    </svg>
  </IconWrapper>
)

export default ArrowRightLongIcon
