import React, { useCallback, useMemo } from 'react'

import { StyledSlider, StyledSliderSlide } from './styles'

const Slider = ({
  slidesArray,
  sliderOptions,
  slideComponent: SlideComponent,
  as,
  alt,
}) => {
  const renderSlide = useCallback(
    ({ ...rest }, index) => (
      <StyledSliderSlide key={index}>
        <SlideComponent {...{ as, alt, ...rest }} />
      </StyledSliderSlide>
    ),
    [alt, as],
  )

  const slides = useMemo(() => slidesArray?.map(renderSlide), [
    slidesArray,
    renderSlide,
  ])

  return <StyledSlider {...sliderOptions}>{slides}</StyledSlider>
}

export default Slider
