import styled from 'styled-components'

import { StyledNavArrow } from '../styles'

const StyledSquareArrows = styled(StyledNavArrow)`
  width: 4.375rem;
  height: 4.375rem;
  background: ${({ theme }) => theme.colors.black};

  svg {
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.75rem;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
  }
`

const StyledSquareArrowPrev = styled(StyledSquareArrows)`
  transform: translateY(-20%) translateY(2.1875rem) translateY(0.0625rem);
`

const StyledSquareArrowNext = styled(StyledSquareArrows)`
  transform: translateY(-20%) translateY(-2.1875rem) rotate(-180deg);
`

export { StyledSquareArrowPrev, StyledSquareArrowNext }
