import styled, { css } from 'styled-components'

import media, { customMedia } from '../../../common/MediaQueries'
import Inner from '../../Inner'
import {
  StyledSquareArrowPrev,
  StyledSquareArrowNext,
} from '../SliderNavigation/SquareArrows/styles'
import { transition } from '../../../common/mixins'
import { BG_TYPES } from '../../../constants/bgTypes'

const StyledOtherModelDetailsDots = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: calc(1.875rem - 0.3125rem);
  left: 1.875rem;
  max-width: 80%;

  button {
    display: flex;
    flex-shrink: 0;
    border-radius: 3.125rem;
    width: 0.5rem;
    height: 0.5rem;
    font-size: 0;
    line-height: 0;
    color: transparent;
    background: rgba(17, 17, 17, 0.4);
    cursor: pointer;
  }

  li {
    display: flex;
    padding: 0.3125rem;
    cursor: pointer;

    &.slick-active {
      button {
        background: rgba(17, 17, 17, 0.9);
      }
    }
  }

  @media ${media.tabletSmall} {
    display: none;
  }

  @media ${media.phone} {
    button {
      ${transition}
    }

    li {
      &:hover {
        button {
          background: rgba(17, 17, 17, 0.9);
        }
      }
    }
  }
`

const StyledOtherModelsSliderDetails = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;

  ${({ bg }) =>
    bg === BG_TYPES.white &&
    css`
      background: ${({ theme }) => theme.colors.lightGrey4};
    `}

  ${({ bg }) =>
    bg === BG_TYPES.black &&
    css`
      background: ${({ theme }) => theme.colors.black};

      ${StyledSquareArrowPrev},
      ${StyledSquareArrowNext} {
        background: ${({ theme }) => theme.colors.white};
        svg {
          color: ${({ theme }) => theme.colors.black};
        }

        &:hover {
          background: ${({ theme }) => theme.colors.yellow};
        }
      }

      ${StyledOtherModelDetailsDots} {
        button {
          background: rgba(255, 255, 255, 0.4);
        }

        li {
          &:hover,
          &.slick-active {
            button {
              background: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    `}

  @media ${media.tabletSmall} {
    margin-top: 6rem;
    margin-bottom: 6rem;
    max-width: 40.75rem;

    &::before,
    &::after {
      content: '';
      display: block;
      background: inherit;
      width: 16rem;
      height: 15rem;
      position: absolute;
      left: 10.3125rem;
    }

    &::before {
      bottom: 100%;
    }

    &::after {
      top: 100%;
    }

    &::before,
    &::after {
      content: '';
      width: 20rem;
    }

    ${({ bg }) =>
      bg === BG_TYPES.white &&
      css`
        background: ${({ theme }) => theme.colors.white};
      `}
  }

  @media ${media.tablet} {
    &::before,
    &::after {
      content: '';
      width: 23.125rem;
    }
  }
`

const StyledOtherModelsSliderInner = styled(Inner)`
  padding-top: 3rem;
  display: flex;

  @media ${customMedia.maxTabletSmall} {
    padding-top: 0;
    margin-top: -2.25rem;
  }

  @media ${media.tabletSmall} {
    padding-top: 0;
  }
`

const StyledOtherModelsSliderImages = styled.div`
  .slick-list {
    overflow: visible;
  }

  @media ${customMedia.maxTabletSmall} {
    height: 21.125rem;
    .slick-track {
      /* safari ios fix */
      margin-left: calc(-100vw - 0.5px);
    }
  }

  @media ${media.tabletSmall} {
    max-width: 40vw;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .slick-slider {
      margin-left: -20vw;
    }
  }
`

const StyledOtherModelsSlider = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: currentColor;

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slider,
  .slick-list {
    height: 100%;
  }

  .slick-slide {
    display: flex;
    width: 100%;

    div {
      &:focus {
        outline: none;
      }
    }
    div:not([class]) {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  ${StyledSquareArrowPrev},
  ${StyledSquareArrowNext} {
    display: none;
  }

  @media ${customMedia.maxTabletSmall} {
    flex-direction: column-reverse;

    ${({ alt }) =>
      alt &&
      css`
        ${StyledOtherModelDetailsDots} {
          display: none;
        }
        ${StyledSquareArrowPrev},
        ${StyledSquareArrowNext} {
          display: flex;
          width: 2.5rem;
          height: 2.5rem;
          transform: translateY(-50%);

          svg {
            font-size: 0.5rem;
          }
        }
        ${StyledSquareArrowPrev} {
          left: 0;
          transform: translateY(-50%);
        }
        ${StyledSquareArrowNext} {
          right: 0;
          transform: translateY(-50%) rotate(-180deg);
        }
      `}
  }

  @media ${media.tabletSmall} {
    justify-content: center;

    .slick-slide {
      cursor: grab;
    }

    ${StyledSquareArrowPrev},
    ${StyledSquareArrowNext} {
      display: flex;
    }
  }
`

export {
  StyledOtherModelsSlider,
  StyledOtherModelsSliderInner,
  StyledOtherModelsSliderDetails,
  StyledOtherModelDetailsDots,
  StyledOtherModelsSliderImages,
}
