import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledOtherModelDetails,
  StyledOtherModelDetailsLink,
  StyledOtherModelDetailsTitle,
  StyledOtherModelDetailsName,
  StyledOtherModelDetailsDesc,
  StyledOtherModelDetailsAction,
} from './styles'

const OtherModel = ({ name, description, url, alt, bg }) => (
  <StyledOtherModelDetails {...{ alt }}>
    <StyledOtherModelDetailsLink to={url}>
      <StyledOtherModelDetailsTitle {...{ bg }}>
        Our models
      </StyledOtherModelDetailsTitle>
      <StyledOtherModelDetailsName as="h3">{name}</StyledOtherModelDetailsName>
      <StyledOtherModelDetailsDesc>{description}</StyledOtherModelDetailsDesc>
      <StyledOtherModelDetailsAction>
        View Profile
      </StyledOtherModelDetailsAction>
    </StyledOtherModelDetailsLink>
  </StyledOtherModelDetails>
)

export default OtherModel

OtherModel.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
  alt: PropTypes.bool,
  bg: PropTypes.string,
}
