import styled from 'styled-components'

import { transition } from '../../../common/mixins'

const StyledNavArrow = styled.button`
  position: absolute;
  top: 50%;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 10;
  ${transition}
`

export { StyledNavArrow }
