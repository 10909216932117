import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowRightIcon = () => (
  <IconWrapper width="0.7">
    <svg viewBox="0 0 43 62" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          x="-100%"
          y="-55%"
          width="295.5%"
          height="210%"
          filterUnits="objectBoundingBox"
          id="arrowRight"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.671802499 0 0 0 0 0.0655487805 0 0 0 1 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        filter="url(#arrowRight)"
        transform="matrix(-1 0 0 1 32 11)"
        fill="currentColor"
        fillRule="nonzero"
        stroke="currentColor"
        strokeWidth="3"
      >
        <path d="M2.798 19.964L20.97 1.931a1.12 1.12 0 000-1.598 1.141 1.141 0 00-1.61 0L.377 19.169a1.12 1.12 0 000 1.599L19.36 39.596c.22.218.515.335.801.335.287 0 .582-.109.801-.335a1.12 1.12 0 000-1.598L2.798 19.964z" />
      </g>
    </svg>
  </IconWrapper>
)

export default ArrowRightIcon
