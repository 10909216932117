// React Slick

import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import OtherModelDetails from '../../OtherModel/OtherModelDetails/OtherModelDetails'
import OtherModelImage from '../../OtherModel/OtherModelImage/OtherModelImage'
import { fluidPropType } from '../../../constants/proptypes'
import {
  StyledOtherModelsSlider,
  StyledOtherModelsSliderInner,
  StyledOtherModelsSliderDetails,
  StyledOtherModelDetailsDots,
  StyledOtherModelsSliderImages,
} from './styles'
import defaultSliderOptions from '../../../constants/defaultSliderConfig'
import {
  PrevButton,
  NextButton,
} from '../SliderNavigation/SquareArrows/SquareArrows'
import { BG_TYPES } from '../../../constants/bgTypes'

const sliderDetailsOptions = {
  ...defaultSliderOptions,
  fade: true,
  slidesToShow: 1,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  arrows: true,
  dots: false,
  appendDots: (dots) => (
    <StyledOtherModelDetailsDots>{dots}</StyledOtherModelDetailsDots>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        dots: true,
      },
    },
  ],
}

const sliderImagesOptions = {
  ...defaultSliderOptions,
  slidesToShow: 1,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
      },
    },
  ],
}

const OtherModelsSlider = ({ details, images, alt, bg }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  const slider1 = useRef()
  const slider2 = useRef()

  useEffect(() => {
    if (slider1.current) {
      setNav1(slider1.current)
    }
    if (slider2.current) {
      setNav2(slider2.current)
    }
  }, [slider1, slider2])

  return (
    <StyledOtherModelsSlider {...{ alt }}>
      <StyledOtherModelsSliderInner>
        <StyledOtherModelsSliderDetails bg={bg}>
          <Slider {...sliderDetailsOptions} asNavFor={nav2} ref={slider1}>
            {details.map(({ ...rest }, index) => (
              <OtherModelDetails key={index} {...{ alt, bg, ...rest }} />
            ))}
          </Slider>
        </StyledOtherModelsSliderDetails>
      </StyledOtherModelsSliderInner>
      <StyledOtherModelsSliderImages>
        <Slider {...sliderImagesOptions} asNavFor={nav1} ref={slider2}>
          {images.map(({ ...rest }, index) => (
            <OtherModelImage key={index} {...{ alt, ...rest }} />
          ))}
        </Slider>
      </StyledOtherModelsSliderImages>
    </StyledOtherModelsSlider>
  )
}

export default OtherModelsSlider

OtherModelsSlider.defaultProps = {
  bg: BG_TYPES.white,
}

OtherModelsSlider.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: fluidPropType,
    }),
  ).isRequired,
  alt: PropTypes.bool,
  bg: PropTypes.string,
}
