import React from 'react'

import ArrowRightIcon from '../../../Icons/ArrowRightIcon'
import { StyledShadowedArrowPrev, StyledShadowedArrowNext } from './styles'

export const PrevButton = ({ onClick }) => (
  <StyledShadowedArrowPrev aria-label="prev" {...{ onClick }}>
    <ArrowRightIcon />
  </StyledShadowedArrowPrev>
)

export const NextButton = ({ onClick }) => (
  <StyledShadowedArrowNext aria-label="next" {...{ onClick }}>
    <ArrowRightIcon />
  </StyledShadowedArrowNext>
)
