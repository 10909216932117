import shuffle from 'lodash/shuffle'

import { URL_PATHS } from '../../constants/urlPaths'

const mapOtherModelsToList = (models) => {
  const shuffledModels = shuffle(models)

  const otherModelsSliderDetails = shuffledModels.map((model) => {
    const { acf, id, title, slug } = model.node

    return {
      id,
      description: acf.description,
      name: title,
      url: `${URL_PATHS.MODELS}/${slug}`,
    }
  })

  const otherModelsSliderImages = shuffledModels.map((model) => {
    const { featuredImage } = model.node

    return {
      fluid: featuredImage?.imageFile?.childImageSharp.fluid,
    }
  })

  otherModelsSliderDetails.push(otherModelsSliderDetails.shift())

  return { otherModelsSliderImages, otherModelsSliderDetails }
}

export default mapOtherModelsToList
